<div class="navbar navbar-inverse navbar-fixed-top main-menu" ng-style="mainMenu.customNavbarStyle">
    <div class="container-fluid">
        <div class="navbar-header">
            <button type="button" class="navbar-toggle" ng-init="$root.collapsed = true"
                    ng-click="$root.collapsed = !$root.collapsed">
                <span class="sr-only">Toggle navigation</span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
            </button>
            <a class="navbar-brand" ui-sref="dashboard" ui-sref-opts="{reload: true}">
                <img ng-if="mainMenu.accountId !== 9 && mainMenu.accountId !== 20" class="image-responsive" src="images/icon.svg" alt="VC"/>
                <img ng-if="mainMenu.accountId === 20" class="image-responsive" src="images/dw-icon-white.svg" alt="DW"/>
                <img ng-if="mainMenu.accountId === 9" class="image-responsive" src="images/gwf-icon.svg" alt="GWF"/>
            </a>
        </div>

        <div class="collapse navbar-collapse" uib-collapse="$root.collapsed" ng-controller="ServiceRoutingCtrl as serviceRouting">
            <ul class="nav navbar-nav">
                <li ui-sref-active="active" ng-if="mainMenu.canAccessState('customers.list')">
                    <a ui-sref="customers.list"
                       ng-if="mainMenu.tenantLabel === null">{{'ADMIN_CUSTOMERS' | translate}}</a>
                    <a ui-sref="customers.list" ng-if="mainMenu.tenantLabel !== null">{{mainMenu.tenantLabel}}</a>
                </li>
                <li ui-sref-active="active" ng-if="mainMenu.canAccessState('businesses.list')">
                    <a ui-sref="businesses.list"
                       ng-if="mainMenu.businessLabel === null">{{'WINERY_MANAGEMENT_WINERIES' | translate}}</a>
                    <a ui-sref="businesses.list" ng-if="mainMenu.businessLabel !== null">{{mainMenu.businessLabel}}</a>
                </li>
                <li uib-dropdown>
                    <a uib-dropdown-toggle href="#" ng-style="mainMenu.customNavbarStyle">
                        {{'MODULE_BASE' | translate}}
                        <span class="caret"></span>
                    </a>
                    <ul uib-dropdown-menu>
                        <li ui-sref-active="active" ng-if="mainMenu.canAccessState('parcels.list')">
                            <a ui-sref="parcels.list">{{'PARCELS' | translate}}</a>
                        </li>
                        <li ui-sref-active="active" ng-if="mainMenu.canAccessState('users.list')">
                            <a ui-sref="users.list">{{'USERS' | translate}}</a>
                        </li>
                        <li ui-sref-active="active" ng-if="mainMenu.canAccessState('temporaryWorkers.list')">
                            <a ui-sref="temporaryWorkers.list({message: null})">
                                {{'TEMPORARY_WORKERS' | translate}}</a>
                        </li>
                        <li>
                            <a href
                               ng-click="serviceRouting.routeToService('/equipment')">{{'EQUIPMENTS' | translate}}</a>
                        </li>
                        <li ui-sref-active="active" ng-if="mainMenu.canAccessState('orders.list')">
                            <a ui-sref="orders.list">{{'ORDERS' | translate}}</a>
                        </li>

                        <li ui-sref-active="active" ng-if="mainMenu.canAccessState('comments')">
                            <a ui-sref="comments">{{'COMMENTS' | translate}}</a>
                        </li>
                        <li ui-sref-active="active" ng-if="mainMenu.canAccessState('managementUnits.list')">
                            <a ui-sref="managementUnits.list">{{'MANAGEMENT_UNITS' | translate}}</a>
                        </li>
                        <li ui-sref-active="active" ng-if="mainMenu.canAccessState('orderTypes.list')">
                            <a ui-sref="orderTypes.list">{{'ORDER_ORDER_TYPES' | translate}}</a>
                        </li>
                        <li ui-sref-active="active" ng-if="mainMenu.canAccessState('poi.list')">
                            <a ui-sref="poi.list">{{'POIS' | translate}}</a>
                        </li>
                        <li ui-sref-active="active" ng-if="mainMenu.canUseProposal">
                            <a href ng-click="serviceRouting.routeToService('/proposal')">{{'mainMenu.proposal' | translate}}</a>
                        </li>
                        <li ui-sref-active="active" ng-if="mainMenu.canUseRestrictions">
                            <a href ng-click="serviceRouting.routeToService('/area/restriction')">{{'mainMenu.restriction' | translate}}</a>
                        </li>
                        <li ui-sref-active="active" ng-if="mainMenu.customerId === 406">
                            <a href ng-click="serviceRouting.routeToService('/area/cuttingProvision')">{{'mainMenu.cuttingProvision' | translate}}</a>
                        </li>
                    </ul>
                </li>
                <li uib-dropdown
                    ng-if="mainMenu.canAccessState('timeTracking')">
                    <a uib-dropdown-toggle href="#" ng-style="mainMenu.customNavbarStyle">
                        {{'MODULE_TIME_TRACKING' | translate}}
                        <span class="caret"></span>
                    </a>
                    <ul uib-dropdown-menu>
                        <li ui-sref-active="active">
                            <a ui-sref="timeTracking.costCenters.list">{{'TIME_TRACKING_COST_CENTERS' | translate}}</a>
                        </li>
                        <!-- Link to Version 8 -->
                        <li ui-sref-active="active" ng-if="mainMenu.canAccessTimeTrackingTimeLogs">
                            <a href ng-click="serviceRouting.routeToService('/worklog')">{{('TIME_TRACKING_TIME_LOGS' | translate) + ' V8'}}</a>
                        </li>
                        <li ui-sref-active="active" ng-if="mainMenu.canUseTimeSettings">
                            <a href ng-click="serviceRouting.routeToService('/worklog/settings')">{{('TIME_TRACKING_SETTINGS' | translate) + ' V8'}}</a>
                        </li>
                        <li ui-sref-active="active">
                            <a href ng-click="serviceRouting.routeToService('/worklog/stats')">{{('TIME_TRACKING_STATISTICS' | translate) + ' V8'}}</a>
                        </li>
                        <li ui-sref-active="active">
                            <a href ng-click="serviceRouting.routeToService('/businessintelligence')">{{('mainMenu.businessintelligence' | translate)}}</a>
                        </li>
                    </ul>
                </li>
                <li uib-dropdown>
                    <a uib-dropdown-toggle href="#" ng-style="mainMenu.customNavbarStyle">
                        {{'EXPORTS' | translate}}
                        <span class="caret"></span>
                    </a>
                    <ul uib-dropdown-menu>
                        <li ui-sref-active="active" ng-if="mainMenu.canAccessState('plantProtection.exports.edit')">
                            <a ui-sref="plantProtection.exports.edit">{{'PLANT_PROTECTION_EXPORT_TITLE' | translate}}</a>
                        </li>
                        <li ui-sref-active="active"
                            ng-if="mainMenu.canAccessState('fertilization.nutrientComparisonExports.edit')">
                            <a ui-sref="fertilization.nutrientComparisonExports.edit">{{'FERTILIZATION_NUTRIENT_COMPARISON_EXPORT' | translate}}</a>
                        </li>
                        <li ui-sref-active="active"
                            ng-if="mainMenu.canAccessState('fertilization.phDemandExports.edit')">
                            <a ui-sref="fertilization.phDemandExports.edit">{{'FERTILIZATION_PH_DEMAND_EXPORT' | translate}}</a>
                        </li>
                        <li ui-sref-active="active"
                            ng-if="mainMenu.canAccessState('fertilization.nDemandExports.edit')">
                            <a ui-sref="fertilization.nDemandExports.edit">{{'FERTILIZATION_N_DEMAND_EXPORT' | translate}}</a>
                        </li>
                        <li ui-sref-active="active">
                            <a ui-sref="exports.list">{{'ALL_EXPORTS' | translate}}</a>
                        </li>
                    </ul>
                </li>
                <li ng-if="mainMenu.canUseBonitur" ng-controller="ServiceRoutingCtrl as serviceRouting">
                    <a href ng-if="mainMenu.bonitur === null" ng-click="serviceRouting.routeToService('/classification')">{{('mainMenu.classification' | translate)}}</a>
                    <a href ng-if="mainMenu.bonitur !== null" ng-click="serviceRouting.routeToService('/classification')">{{mainMenu.bonitur}}</a>
                </li>
                <li ng-if="mainMenu.canUseHarvestPlanning" ng-controller="ServiceRoutingCtrl as serviceRouting">
                    <a href
                       ng-click="serviceRouting.routeToService('/harvestplanning')">{{'SERVICE.HARVEST_PLANNING' | translate}}</a>
                </li>
                <li ng-if="mainMenu.canUseStockManagement" ng-controller="ServiceRoutingCtrl as serviceRouting">
                    <a href
                       ng-click="serviceRouting.routeToService('/stock')">{{'SERVICE.STOCK_MANAGEMENT' | translate}}</a>
                </li>
                <li ng-repeat="item in mainMenu.items"
                    class="active">
                    <a>
                        <span ng-if="item.translate !== false">{{item.label | translate}}</span>
                        <span ng-if="item.translate === false">{{item.label}}</span>
                    </a>
                </li>
            </ul>
            <div ng-controller="LoginCtrl as login">
                <ul class="nav navbar-nav navbar-right">
                    <li>
                        <a ng-href="{{window.location.origin + '/ui/dashboard'}}">
                            <img class="image-responsive" height="17" src="images/logo.svg" alt="VYC"/>
                        </a>
                    </li>
                    <li>
                        <a class="show-help" href ng-click="login.showHelp()">
                            <span style="color: #d9534f" class="glyphicon glyphicon-question-sign"></span>
                        </a>
                    </li>
                    <li uib-dropdown ng-if="!login.UserService.isImpersonating()">
                        <a uib-dropdown-toggle href="#">
                            {{login.UserService.user.email}}
                            <span class="caret"></span>
                        </a>
                        <ul uib-dropdown-menu>
                            <li>
                                <a href ng-click="login.logout()">
                                    {{'LOGOUT' | translate}}
                                </a>
                            </li>
                            <li>
                                <a href ng-click="login.goToSettings()">{{'SETTINGS' | translate}}</a>
                            </li>
                        </ul>
                    </li>

                    <li uib-dropdown ng-if="login.UserService.isImpersonating()">
                        <a uib-dropdown-toggle href="#" ng-style="mainMenu.customNavbarStyle">
                            {{login.UserService.user.customerLabel}}
                            <span class="caret"></span>
                        </a>
                        <ul uib-dropdown-menu>
                            <li>
                                <a href ng-click="login.goToSettings()">{{'SETTINGS' | translate}}</a>
                            </li>
                        </ul>
                    </li>

                    <li uib-dropdown>
                        <a uib-dropdown-toggle href="#" ng-style="mainMenu.customNavbarStyle">
                            <span class="glyphicon glyphicon-globe"></span>
                        </a>
                        <ul class="dropdown-menu">
                            <li><a href="#" ng-click="mainMenu.changeLanguage('bg')">{{ 'LANGUAGE.BG' | translate }}</a>
                            </li>
                            <li><a href="#" ng-click="mainMenu.changeLanguage('cs')">{{ 'LANGUAGE.CS' | translate }}</a>
                            </li>
                            <li><a href="#" ng-click="mainMenu.changeLanguage('de')">{{ 'LANGUAGE.DE' | translate }}</a>
                            </li>
                            <li><a href="#" ng-click="mainMenu.changeLanguage('en')">{{ 'LANGUAGE.EN' | translate }}</a>
                            </li>
                            <li><a href="#" ng-click="mainMenu.changeLanguage('es')">{{ 'LANGUAGE.ES' | translate }}</a>
                            </li>
                            <li><a href="#" ng-click="mainMenu.changeLanguage('fr')">{{ 'LANGUAGE.FR' | translate }}</a>
                            </li>
                            <li><a href="#" ng-click="mainMenu.changeLanguage('it')">{{ 'LANGUAGE.IT' | translate }}</a>
                            </li>
                            <li><a href="#" ng-click="mainMenu.changeLanguage('pl')">{{ 'LANGUAGE.PL' | translate }}</a>
                            </li>
                            <li><a href="#" ng-click="mainMenu.changeLanguage('pt')">{{ 'LANGUAGE.PT' | translate }}</a>
                            </li>
                            <li><a href="#" ng-click="mainMenu.changeLanguage('ro')">{{ 'LANGUAGE.RO' | translate }}</a>
                            </li>
                        </ul>
                    </li>

                    <li ng-if="!login.UserService.isImpersonating() && login.canImpersonate()">
                        <a href="#"
                           uib-tooltip="{{'ADMIN_IMPERSONATION' | translate}}"
                           tooltip-placement="bottom-right"
                           ng-click="$event.preventDefault(); login.doImpersonate()">
                            <span class="glyphicon glyphicon-sunglasses"></span>
                            <span class="visible-xs-inline">{{'ADMIN_IMPERSONATION' | translate}}</span>
                        </a>
                    </li>
                    <li ng-if="login.UserService.isImpersonating()"
                        class="end-impersonation">
                        <a href="#"
                           uib-tooltip="{{'ADMIN_IMPERSONATION_STOP' | translate}}"
                           tooltip-placement="bottom-right"
                           ng-click="$event.preventDefault(); login.stopImpersonate()">
                            <span class="glyphicon glyphicon-remove"></span>
                            <span class="visible-xs-inline">{{'ADMIN_IMPERSONATION_STOP' | translate}}</span>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>
