<div class="row xs-margin">

  <label class="control-label">{{'PARCELS_PARAM_WINERY' | translate}}</label>
  <select class="form-control"
          ng-options="business.id as business.label for business in edit.businesses"
          ng-model="edit.item.businessId"
          name="business"
          ng-disabled="false"
          required>
    <option value=""
            ng-if="!edit.item.businessId">{{'MAKE_CHOICE' | translate}}
    </option>
  </select>


  <div class="col-md-3">

    <h3>Select files</h3>

    <input type="file"
           id="shape-file-upload"
           nv-file-select=""
           uploader="edit.multiUploader"
           multiple/><br/>
  </div>

  <div class="col-md-9" style="margin-bottom: 40px">

    <h3>Upload queue</h3>
    <p>Queue length: {{ edit.multiUploader.queue.length }}</p>

    <table class="table">
      <thead>
      <tr>
        <th width="50%">Name</th>
        <th ng-show="edit.multiUploader.isHTML5">Size</th>
        <th ng-show="edit.multiUploader.isHTML5">Progress</th>
        <th>Status</th>
        <th>Actions</th>
      </tr>
      </thead>
      <tbody>
      <tr ng-repeat="item in edit.multiUploader.queue">
        <td><strong>{{ item.file.name }}</strong></td>
        <td ng-show="edit.multiUploader.isHTML5" nowrap>{{ item.file.size/1024/1024|number:2 }} MB</td>
        <td ng-show="edit.multiUploader.isHTML5">
          <div class="progress" style="margin-bottom: 0;">
            <div class="progress-bar" role="progressbar" ng-style="{ 'width': item.progress + '%' }"></div>
          </div>
        </td>
        <td class="text-center">
          <span ng-show="item.isSuccess"><i class="glyphicon glyphicon-ok"></i></span>
          <span ng-show="item.isCancel"><i class="glyphicon glyphicon-ban-circle"></i></span>
          <span ng-show="item.isError"><i class="glyphicon glyphicon-remove"></i></span>
        </td>
        <td nowrap>
          <button type="button" class="btn btn-success btn-xs" ng-click="item.upload()" ng-disabled="item.isReady || item.isUploading || item.isSuccess">
            <span class="glyphicon glyphicon-upload"></span> Upload
          </button>
          <button type="button" class="btn btn-warning btn-xs" ng-click="item.cancel()" ng-disabled="!item.isUploading">
            <span class="glyphicon glyphicon-ban-circle"></span> Cancel
          </button>
          <button type="button" class="btn btn-danger btn-xs" ng-click="edit.removeItemToUpload(item)">
            <span class="glyphicon glyphicon-trash"></span> Remove
          </button>
        </td>
      </tr>
      </tbody>
    </table>

    <div>
      <div>
        Queue progress:
        <div class="progress" style="">
          <div class="progress-bar" role="progressbar" ng-style="{ 'width': edit.multiUploader.progress + '%' }"></div>
        </div>
      </div>
      <button type="button" class="btn btn-success btn-s" ng-click="edit.multiUploader.uploadAll()" ng-disabled="!edit.multiUploader.getNotUploadedItems().length">
        <span class="glyphicon glyphicon-upload"></span> Upload all
      </button>
      <button type="button" class="btn btn-warning btn-s" ng-click="edit.multiUploader.cancelAll()" ng-disabled="!edit.multiUploader.isUploading">
        <span class="glyphicon glyphicon-ban-circle"></span> Cancel all
      </button>
      <button type="button" class="btn btn-danger btn-s" ng-click="edit.multiUploader.clearQueue()" ng-disabled="!edit.multiUploader.queue.length">
        <span class="glyphicon glyphicon-trash"></span> Remove all
      </button>
    </div>
  </div>
</div>



<div class="shapeConverter row">
  <div ng-repeat="(listName, list) in edit.shapeCsvLists" class="col-xs-3">
    <div class="panel panel-info">
      <div class="panel-heading">
        <h3 class="panel-title">List {{listName}}</h3>
      </div>
      <ul dnd-list="list">
        <!-- The dnd-draggable directive makes an element draggable and will
             transfer the object that was assigned to it. If an element was
             dragged away, you have to remove it from the original list
             yourself using the dnd-moved attribute -->
        <li ng-repeat="item in list"
            dnd-draggable="item"
            dnd-moved="list.splice($index, 1)"
            dnd-effect-allowed="move"
            dnd-selected="edit.shapeCsvLists.selected = item"
            ng-class="{'selected': edit.shapeCsvLists.selected === item}"
        >
          {{item.label}}
        </li>
      </ul>
    </div>
  </div>
</div>
<div class="shapeConverter row">
  <div ng-repeat="(listName, list) in edit.ourHeaders.lists" class="col-xs-3">
    <div class="panel panel-info">
      <div class="panel-heading">
        <h3 class="panel-title">List {{listName}}</h3>
      </div>
      <!--ul ng-if="!edit.isEmpty(edit.ourHeaders.lists.todo)" dnd-list="list"-->
      <ul dnd-list="list">
        <!-- The dnd-draggable directive makes an element draggable and will
             transfer the object that was assigned to it. If an element was
             dragged away, you have to remove it from the original list
             yourself using the dnd-moved attribute -->
        <li ng-repeat="item in list"
            dnd-draggable="item"
            dnd-moved="list.splice($index, 1)"
            dnd-effect-allowed="move"
            dnd-selected="edit.ourHeaders.selected = item"
            ng-class="{'selected': edit.ourHeaders.selected === item}"
        >
          {{item.name|translate}}
        </li>
      </ul>
    </div>
  </div>
  <div ng-repeat="(listName, list) in edit.uploadHeaders.lists" class="col-xs-3">
    <div class="panel panel-info">
      <div class="panel-heading">
        <h3 class="panel-title">List {{listName}}</h3>
      </div>
      <!--ul ng-if="!edit.isEmpty(edit.uploadHeaders.lists.todo)" dnd-list="list"-->
      <ul dnd-list="list">
        <!-- The dnd-draggable directive makes an element draggable and will
             transfer the object that was assigned to it. If an element was
             dragged away, you have to remove it from the original list
             yourself using the dnd-moved attribute -->
        <li ng-repeat="item in list"
            dnd-draggable="item"
            dnd-moved="list.splice($index, 1)"
            dnd-effect-allowed="move"
            dnd-selected="edit.uploadHeaders.selected = item"
            ng-class="{'selected': edit.uploadHeaders.selected === item}"
        >
          {{item.label}}
        </li>
      </ul>
    </div>
  </div>
</div>
  <button type="button" class="btn btn-default" ng-click="edit.convertShapes();"><span>convert</span></button>
<h3 class="panel-title">List convertsuccess</h3>
<ul dnd-list="list">
  <li ng-repeat="item in vm.convertsuccess">
    {{item.label}}
  </li>
</ul>
