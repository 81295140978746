(function () {
  'use strict';

  class DashboardCtrl {
    constructor(
      CrudApi,
      $q,
      $state,
      $stateParams,
      $filter,
      tileColors,
      MapExtApi,
      $scope,
      MapHelper,
      $interval,
      $log,
      CustomerMapDataService,
      CustomerMapDataTemporaryService,
      ModuleService,
      UserService,
      permissions,
      orderStatus
    ) {
      /* eslint-disable no-unused-vars */
      let vm = this,
          refresh,
          requestPromise = null,
          refreshPromise = null,
          tempWorkersActivatedForOrder = {},
          activatedOrders = [],
          activatedOrdersVineyardsInfo = {},
          userProgressObject = {},
          temporaryWorkersObject = {},
          orderIndexObject = {};

      vm.quantity = 6;
      vm.canReadGPSProfile = UserService.hasPerm(permissions.BASE_CAN_READ_GPS_PROFILE);

      vm.isLoading = true;

      $scope.$on('$destroy', () => {
        $interval.cancel(refresh);
        MapHelper.clearMap();
      });

      vm.vcMapData = {
        activatedOrdersArr: activatedOrders,
        activatedOrdersVineyardsInfo: activatedOrdersVineyardsInfo,
        orderIndexObject: orderIndexObject,
        userProgressObject: userProgressObject,
        temporaryWorkersObject: temporaryWorkersObject
      };

      vm.vineyardsMapDataBuffer = {};

      /*
       * Refresh the data every 30 seconds
       */
      // refresh = $interval(() => {
      //   if (refreshPromise === null) {
      //     refreshPromise = $q.all([loadOrderData(), loadUserData(activatedOrders)])
      //       .then(() => vm.message = null)
      //       .catch(error => {
      //         $log.error('Could not reload data:', error);
      //         vm.message = 'loadingError';
      //       })
      //       .finally(() => refreshPromise = null);
      //   } else {
      //     $log.info('No reload scheduled as there is another one still running');
      //   }
      // }, 30000);

      loadOrderData().finally(() => vm.isLoading = false);

      vm.loadMore = () => {
        vm.quantity = 12;
      };

      vm.goToAllTasks = () => {
        $state.go('orders.list');
      };

      vm.dynamicPopover = {
        templateUrl: 'dashboard/views/worker-bar.tpl.html'
      };

      vm.toggleTempWorkers = (order, state) => {
        angular.forEach(temporaryWorkersObject, (tworkers, key) => {
          if (parseInt(key, 10) === order.id) {
            angular.forEach(tworkers, (marker) => {
              marker.visible = state;
            });
            if (state) {
              tempWorkersActivatedForOrder[order.id] = true;
            } else {
              delete tempWorkersActivatedForOrder[order.id];
            }
          }
        });
      };

      vm.activateTile = (order, state) => {
        // To count the occurance of a vineyard in all activated orders
        if (angular.isDefined(order)) {
          // Case if your turn on switch for a tile
          if (state) {
            // Keep record or activated order ids
            activatedOrders.push(order.id);
            activatedOrdersVineyardsInfo[order.id] = order.siteIds;

            loadUserData([order.id], false);
          } else {
            // Delete the related vineyards as user turned off the tile
            let index = activatedOrders.indexOf(order.id);

            // Remove the order id from activated list
            activatedOrders.splice(index, 1);

            // Delete the vineyards info for corresponding order
            delete activatedOrdersVineyardsInfo[order.id];

            loadUserData(activatedOrders, true, false);

            vm.toggleTempWorkers(order, false);

            if (angular.equals({}, activatedOrdersVineyardsInfo)) {
              MapHelper.clearMap();
              MapHelper.initMap();
            }
          }
        }
      };

      function loadUserData(activatedOrdersArray, cleanUp = true, noRequests = false) {
        let userPromises = {},
            mapPromises = {};

        angular.forEach(vm.ordersArr.filter((order, idx) => idx < vm.quantity && activatedOrdersArray.indexOf(order.id) > -1), order => {
          // Loop over the order's vineyards and get map data for each of them
          for (let i = 0; i < order.siteIds.length; i++) {
            const siteId = order.siteIds[i];
            if (!mapPromises.hasOwnProperty(siteId)) {
              mapPromises[siteId] = getMapPromise(noRequests, siteId);
            }
          }

          // Call Only If Users are assigned to order
          if (order.assignedUserIds.length > 0 && vm.canReadGPSProfile === true) {
            userPromises[order.id] = !noRequests ? MapExtApi.save({
              action: 'order',
              id: order.id,
              latestOnly: true
            }, order.assignedUserIds).$promise : $q.resolve();
          }
        });

        return $q.all({mapData: $q.all(mapPromises), userData: $q.all(userPromises)}).then(({mapData, userData}) => {
          angular.forEach(mapData, function (value, key) {
            if (angular.isDefined(value)) {
              vm.vineyardsMapDataBuffer[key] = value;
            }
          });

          angular.forEach(userData, function (progressData, key) {
            if (angular.isDefined(progressData)) {
              if (angular.isDefined(progressData.userCoordinates)) {
                userProgressObject[key] = {};
                // Loop over user coordinates and temp worker coordinates to show the latest position and temp workers
                progressData.userCoordinates.forEach((point) => {
                  // Seperating data on per user basis
                  if (angular.isUndefined(userProgressObject[key][point.userId])) {
                    userProgressObject[key][point.userId] = {};
                  }
                  userProgressObject[key][point.userId].lat = point.latitude;
                  userProgressObject[key][point.userId].lon = point.longitude;
                  userProgressObject[key][point.userId].visible = true;
                });
              } else {
                delete userProgressObject[key];
              }

              if (angular.isDefined(progressData.temporaryWorkerCoordinates)) {
                temporaryWorkersObject[key] = {};
                progressData.temporaryWorkerCoordinates.forEach((point) => {
                  // Seperating data on per worker basis
                  if (angular.isUndefined(temporaryWorkersObject[key][point.temporaryWorkerId])) {
                    temporaryWorkersObject[key][point.temporaryWorkerId] = {};
                  }
                  temporaryWorkersObject[key][point.temporaryWorkerId].lat = point.latitude;
                  temporaryWorkersObject[key][point.temporaryWorkerId].lon = point.longitude;
                  temporaryWorkersObject[key][point.temporaryWorkerId].visible = false;
                  if (tempWorkersActivatedForOrder[key]) {
                    temporaryWorkersObject[key][point.temporaryWorkerId].visible = true;
                  }
                });
              } else {
                delete temporaryWorkersObject[key];
              }
            }
          });

          if (cleanUp) {
            let mapDataKeys = Object.keys(mapData),
                userDataKeys = Object.keys(userData);

            cleanObject(vm.vineyardsMapDataBuffer, (value, key) => mapDataKeys.indexOf(key) === -1);
            cleanObject(userProgressObject, (value, key) => userDataKeys.indexOf(key) === -1);
            cleanObject(temporaryWorkersObject, (value, key) => userDataKeys.indexOf(key) === -1);
          }
        });
      }

      function getMapPromise(noRequest, siteId) {
        if (!noRequest) {
          return CustomerMapDataService.getCustomerMapsDataById(siteId).catch(() => {
            // the given site might be temporary, so look in that cache too
            return CustomerMapDataTemporaryService.getCustomerMapsDataById(siteId).then(data => {
              data.isTemporary = true;
              return data;
            });
          });
        }
        return $q.resolve();
      }

      function loadOrderData() {
        if (requestPromise === null) {
          let promises = {};

          promises.ordersWithProgress = CrudApi.query({
            entity: 'orders',
            status: [orderStatus.STARTED, orderStatus.PAUSED],
            page: 1,
            perPage: 12,
            sortBy: 'progress',
            sortDir: 'desc'
          }).$promise;

          promises.ordersOverdue = CrudApi.query({
            entity: 'orders',
            overdue: 1,
            status: [orderStatus.NONE, orderStatus.STARTED, orderStatus.PAUSED],
            page: 1,
            perPage: 12,
            sortBy: 'deadlineDate'
          }).$promise;

          promises.ordersWithoutProgress = CrudApi.query({
            entity: 'orders',
            status: orderStatus.NONE,
            page: 1,
            perPage: 12,
            sortBy: 'deadlineDate'
          }).$promise;

          promises.commentsByCustomer = CrudApi.query({
            entity: 'comments',
            page: 1,
            perPage: 8,
            sortBy: 'timestamp',
            sortDir: 'DESC',
            commentsType: 'custom'
          }).$promise;

          promises.ordersWithoutUsers = CrudApi.query({
            entity: 'orders',
            hasNoUsers: 1,
            page: 1,
            perPage: 8
          }).$promise;

          promises.users = CrudApi.query({
            entity: 'users',
            extended: true
          }).$promise;

          promises.moduleServiceInitialized = ModuleService.initialize();

          requestPromise = $q.all(promises).then((data) => {
            let ordersArr = [],
                orderOrdersArr = [],
                ordersToDo = [],
                users = [],
                user,
                usersWithoutTaskCount = 0,
                usersWithoutTask = [],
                usersWhoCanDoOrdersCount = 0,
                userBarWidth,
                sortedProgressArr,
                commentsArr,
                oldOrders = vm.vcMapData.ordersArr || [],
                newOrderIds;

            angular.forEach(data.ordersWithProgress, (value) => {
              orderOrdersArr.push(value);
            });

            sortedProgressArr = $filter('orderBy')(orderOrdersArr, '-progress');
            angular.forEach(sortedProgressArr, (value) => {
              ordersArr.push(value);
            });

            if (ordersArr.length < 12) {
              angular.forEach(data.ordersOverdue, (value) => {
                if (!containsObjectId(value.id, ordersArr)) {
                  ordersArr.push(value);
                }
              });
            }

            if (ordersArr.length < 12) {
              let sortedOrdersWithoutProgress = $filter('orderBy')(data.ordersWithoutProgress, ['deadlineDate', 'label']);
              angular.forEach(sortedOrdersWithoutProgress, (value) => {
                if (!containsObjectId(value.id, ordersArr)) {
                  ordersArr.push(value);
                }
              });
            }

            angular.forEach(data.ordersOverdue, (order) => {
              order.isOverdue = true;
              ordersToDo.push(order);
            });
            angular.forEach(data.ordersWithoutUsers, (order) => {
              if (ordersToDo.length < 8 && !containsObjectId(order.id, ordersToDo) && order.status !== orderStatus.FINISHED) {
                ordersToDo.push(order);
                if ((new Date(order.deadlineDate)).getTime() < (new Date()).getTime()) {
                  order.isOverdue = true;
                }
              }
            });
            ordersToDo = ordersToDo.slice(0, 8);

            angular.forEach(ordersToDo, (order) => {
              let userNames = '';
              angular.forEach(order.assignedUserIds, (userId) => {
                user = $filter('filter')(data.users, {id: userId})[0];
                if (angular.isDefined(user) && user !== null) {
                  userNames += user.firstname + ' ' + user.lastname + ';';
                }
              });
              order.userNames = userNames.substr(0, userNames.length - 1);
            });

            users = data.users;
            angular.forEach(data.users, (localUser) => {
              if (ModuleService.userHasPermissions(localUser, [permissions.BASE_CAN_DO_ORDERS]) && localUser.active === true) {
                if (localUser.assignedTaskCount === 0) {
                  usersWithoutTaskCount++;
                  usersWithoutTask.push(localUser);
                }
                usersWhoCanDoOrdersCount++;
              }
            });

            userBarWidth = {
              width: Math.round(usersWithoutTaskCount / usersWhoCanDoOrdersCount * 100) + '%'
            };
            if (data.commentsByCustomer.length > 0) {
              commentsArr = data.commentsByCustomer;
              angular.forEach(data.commentsByCustomer, (comment) => {
                user = $filter('filter')(data.users, {id: comment.userId})[0];
                if (angular.isDefined(user) && user !== null) {
                  comment.user = {firstname: user.firstname, lastname: user.lastname};
                }
              });
            }

            // update the scope variables
            vm.ordersToDo = ordersToDo;
            vm.users = users;
            vm.usersWithoutTask = usersWithoutTask;
            vm.userBarWidth = userBarWidth;
            vm.commentsArr = commentsArr;
            vm.vcMapData.ordersArr = [];
            vm.ordersArr = vm.vcMapData.ordersArr;
            // We need to clear the order tile indices to make sure we won't have any
            // superfluous ones later (it will break the map)
            angular.forEach(Object.keys(orderIndexObject), key => delete orderIndexObject[key]);
            angular.forEach(ordersArr, (order, idx) => {
              vm.vcMapData.ordersArr.push(order);
              orderIndexObject[order.id] = idx;
            });
            // We might have removed orders that were previously activated, we need to remove
            // their associated map data
            newOrderIds = vm.ordersArr.map(order => order.id);
            angular.forEach(oldOrders.filter(order => {
              if (activatedOrders.indexOf(order.id) > -1) {
                let position = newOrderIds.indexOf(order.id);
                // We want to deactivate the order if it is not in the array anymore
                // or it is not displayed anymore
                if (position === -1 || position > 11) {
                  return true;
                }
                if (position > 5) {
                  vm.quantity = 12;
                }
              }
              return false;
            }), order => {
              vm.activateTile(order, false);
              vm.toggleTempWorkers(order, false);
            });
            vm.isLoading = false;
          })
          .finally(() => requestPromise = null);
        }
        return requestPromise;
      }

      vm.getTileColor = getTileColor;
      vm.isTileActivated = isTileActivated;
      vm.showTemporaryWorkers = showTemporaryWorkers;

      function getTileColor(order) {
        return tileColors[orderIndexObject[order.id] % 12];
      }

      function isTileActivated(order) {
        return activatedOrders.indexOf(order.id) > -1;
      }

      function showTemporaryWorkers(order) {
        return !!tempWorkersActivatedForOrder[order.id];
      }

      function containsObjectId(id, list) {
        let i;
        for (i = 0; i < list.length; i++) {
          if (list[i].id === id) {
            return true;
          }
        }

        return false;
      }

      function cleanObject(array, filterFunc) {
        angular.forEach(array, (value, key) => {
          if (filterFunc(value, key)) {
            delete array[key];
          }
        });
      }
    }
  }

  DashboardCtrl.$inject = [
    'CrudApi',
    '$q',
    '$state',
    '$stateParams',
    '$filter',
    'tileColors',
    'MapExtApi',
    '$scope',
    'MapHelper',
    '$interval',
    '$log',
    'CustomerMapDataService',
    'CustomerMapDataTemporaryService',
    'ModuleService',
    'UserService',
    'permissions',
    'orderStatus'
  ];
  /**
   * @ngdoc object
   * @name dashboard.controller:DashboardCtrl
   *
   * @description
   *
   */
  angular
    .module('dashboard')
    .controller('DashboardCtrl', DashboardCtrl);
}());
